import { createRouter, createWebHistory } from 'vue-router'
import { authHstng, stateHstng } from "@/state"

const routes = [
    {
        path: '/',
        name: 'HstngAuth',
        component: () => import('../views/HstngAuth.vue')
    },
    {
        path: '/tpl001',
        name: 'HstngTmplt001',
        beforeEnter: (to, from, next) => {
            const authInf = authHstng()
            const hstng = stateHstng()
          
            if(authInf.getFcltyCode != '' && hstng.getTmplt != null)
                next()
            //에러페이지
        },
        component: () => import('../views/HstngTmplt001.vue'),
        children: [
            {
                // tpl001 index
                path: '/tpl001/index',
                name: 'IndexTpl01',
                component: () => import('../components/tpl001/IndexTpl01.vue'),
            },
            {
                // tpl001 subPage
                path: '/tpl001/sub',
                name: 'SubTpl01',
                component: () => import('../components/tpl001/SubTpl01.vue'),
                children: [
                    {
                        // tpl001 intro
                        path: '/tpl001/sub/intro',
                        name: 'IntroTpl01',
                        component: () => import('../components/tpl001/IntroTpl01.vue'),
                    },
                    {
                        // tpl001 tour
                        path: '/tpl001/sub/tour',
                        name: 'TourTpl01',
                        component: () => import('../components/tpl001/TourTpl01.vue'),
                    },
                    {
                        // tpl001 FcltyStts
                        path: '/tpl001/sub/fcltyStts',
                        name: 'FcltySttsTpl01',
                        component: () => import('../components/tpl001/FcltySttsTpl01.vue'),
                    },
                    {
                        // tpl001 location
                        path: '/tpl001/sub/location',
                        name: 'LocationTpl01',
                        component: () => import('../components/tpl001/LocationTpl01.vue'),
                    },
                    {
                        // tpl001 Entrance
                        path: '/tpl001/sub/entrn',
                        name: 'EntrnTpl01',
                        component: () => import('../components/tpl001/EntrnTpl01.vue'),
                    },
                    {
                        // tpl001 Notice
                        path: '/tpl001/sub/notice/:page',
                        name: 'NoticeTpl01',
                        props: true,
                        component: () => import('../components/tpl001/NoticeTpl01.vue'),
                    },
                    {
                        // tpl001 NoticeDetail
                        path: '/tpl001/sub/noticeCnt',
                        name: 'NoticeDtlTpl01',
                        props: true,
                        component: () => import('../components/tpl001/NoticeDtlTpl01.vue'),
                    },
                    {
                        // tpl001 ProgramSchedule
                        path: '/tpl001/sub/prgrmSchdl',
                        name: 'PrgrmSchdlTpl01',
                        component: () => import('../components/tpl001/PrgrmSchdlTpl01.vue'),
                    },
                    {
                        // tpl001 WeeklyMenu
                        path: '/tpl001/sub/menu',
                        name: 'WeeklyMenuTpl01',
                        component: () => import('../components/tpl001/WeeklyMenuTpl01.vue'),
                    },
                    {
                        // tpl001 Privacy
                        path: '/tpl001/sub/privacy',
                        name: 'PrivacyTpl01',
                        component: () => import('../components/tpl001/PrivacyTpl01.vue'),
                    },
                ],
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
