import { defineStore } from 'pinia'
import { remoteURL } from '@/plugins/yysCmsApi'

let clntInf = defineStore('clntInf', {
    state: () => ({ ip: '', loc: '', zipCd: '', country: '', region: '', city: '', timezone: '' }),

    getters: {
        getClntIp: (state) => state.ip,
        getClntLoc: (state) => state.loc,
        getClntZipCd: (state) => state.zipCd,
        getClntCountry: (state) => state.country,
        getClntRegion: (state) => state.region,
        getClntCity: (state) => state.city,
        getClntTimeZone: (state) => state.timezone
    },
    
    actions: {
        setClntInf(obj) {
            this.ip = obj.ip
            this.loc = obj.loc
            this.zipCd = obj.postal
            this.country = obj.country
            this.region = obj.region
            this.city = obj.city
            this.timezone = obj.timezone
        },
    },

    persist: {
        enabled: true,
        storage: sessionStorage
    },
})

let lgnInf = defineStore('lgnInf', {
    state: () => ({ account: '', name: '', level: '', token: '', key: '' }),

    getters: {
        getLgnAccount: (state) => state.account,
        getLgnNm: (state) => state.name,
        getLgnlv: (state) => state.level,
        getLgnToken: (state) => state.token,
        getLgnKey: (state) => state.key,
    },
    
    actions: {

    },

    persist: {
        enabled: true,
        storage: sessionStorage
    },
})

let notiInf = defineStore('notiInf', {
    state: () => ({ stt: false, ttl:'Info', cntnt: 'information', type: 'info', tmOut: 3000, }),

    getters: {
        getNotiStt: (state) => state.stt,
        getNotiTtl: (state) => state.ttl,
        getNotiCntnt: (state) => state.cntnt,
        getNotiTyp: (state) => state.type,
        getNotiTmOut: (state) => state.tmOut,
    },
    
    actions: {

    },
})

let displayInfStore = defineStore('displayInf', {
    state: () => ({ width: 0, height: 0, isMobile: false, breakPoint: 'lg', }),

    getters: {
        doubleCount: (state) => state.count * 2,
    },
    
    actions: {

    },
})

let glbDlg = defineStore('glbDlg', {
    // typ code guide - 102 ( 1: 시스템 분류, 02: 모달 순번, ex: 101 = YYS에 첫번째 모달 )
    state: () => ({
        isDlg: false, width: '100', typ:'', title: '', psdObj: null,
        onCnclBtn: true, onSuccBtn: true,
        cnclActBtn: false, succActBtn: false,
    }),

    getters: {
        getDlgStt: (state) => state.isDlg,
        getDlgWth: (state) => state.width,
        getDlgTyp: (state) => state.typ,
        getDlgTtl: (state) => state.title,
        getPsdObj: (state) => state.psdObj,
        getCnclBtn: (state) => state.onCnclBtn,
        getSuccBtn: (state) => state.onSuccBtn,
        getCnclActBtn: (state) => state.cnclActBtn,
        getSuccActBtn: (state) => state.succActBtn,
    },
    
    actions: {
        clickCncl() {
            this.cnclActBtn = true

            setTimeout(function(){
                this.cnclActBtn = false
            }, 1000)
        },
        clickSucc() {
            this.succActBtn = true

            setTimeout(function(){
                this.succActBtn = false
            }, 1000)
        }
    },
})

let glbRules = defineStore('glbRules', {
    state: () => ({
        required: value => !!value || 'Field is required',
    }),

    getters: {
        getRequired: (state) => state.required,
    },
    
    actions: {

    },
})

let authHstng = defineStore('authHstng', {
    state: () => ({
        fcltyNum: 0, fcltyClnm: '', seoCont: '', email: '', tel: '', fax: '', zip: '', addr: '', addrDtl: '', rgst: '', ceoNm: '', srvTm: ''
    }),

    getters: {
        getFcltyCode: (state) => state.fcltyNum,
        getFcltyClnm: (state) => state.fcltyClnm,
        getSeoCont: (state) => state.seoCont,
        getFcltyEmail: (state) => state.email,
        getFcltyTel: (state) => state.tel,
        getFcltyFax: (state) => state.fax,
        getFcltyZip: (state) => state.zip,
        getFcltyAddr: (state) => state.addr,
        getFcltyAddrDtl: (state) => state.addrDtl,
        getFcltyRgst: (state) => state.rgst,
        getFcltyCeo: (state) => state.ceoNm,
        getFcltySrvTm: (state) => state.srvTm,
    },
    
    actions: {
        setEmail(txt1, txt2) {
            this.email = txt1 + '@' + txt2
        },
        setTel(txt1, txt2, txt3) {
            this.tel = txt1 + '-' + txt2 + '-' + txt3
        },
        setFax(txt1, txt2, txt3) {
            this.fax = txt1 + '-' + txt2 + '-' + txt3
        },
        setRgst(txt) {
            if(txt.length == 10)
                this.rgst = txt.slice(0, 3) + '-' + txt.slice(3, 5) + '-' + txt.slice(5, 10)
            else
                this.rgst = txt
        },
        setSrvTm(strt, end) {
            this.srvTm = strt + ' ~ ' + end
        },
    },

    persist: {
        enabled: true,
        storage: sessionStorage
    },
})

let stateHstng = defineStore('stateHstng', {
    state: () => ({
        grtngMsg: '', introTtl: '', introCntnt: '', sns1: '',sns2: '', sns3: '', wayCome: '',
        fcltyLttd: '', fcltylngtd: '', tmpltCd: null, fcltyLogo: '', fcltRprs: '', mainImg: [], fcltyTour: []
    }),

    getters: {
        getGrtng: (state) => state.grtngMsg,
        getTtl: (state) => state.introTtl,
        getCntnt: (state) => state.introCntnt,
        getSns1: (state) => state.sns1,
        getSns2: (state) => state.sns2,
        getSns3: (state) => state.sns3,
        getWCome: (state) => state.wayCome,
        getLttd: (state) => state.fcltyLttd,
        getLngtd: (state) => state.fcltylngtd,
        getTmplt: (state) => state.tmpltCd,
        getLogo: (state) => state.fcltyLogo,
        getRprs: (state) => state.fcltRprs,
        getImg: (state) => state.mainImg,
        getTour: (state) => state.fcltyTour,
    },
    
    actions: {
        setLogo(obj) {
            this.fcltyLogo = remoteURL + 'opr/imgView?fileNm=' + obj.fileNm + '&filePth=' + obj.filePth + '&fileKndCd=2'
        },
        setRprs(obj) {
            this.fcltRprs = remoteURL + 'opr/imgView?fileNm=' + obj.fileNm + '&filePth=' + obj.filePth + '&fileKndCd=2'
        },
        setImg(arr) {
            this.mainImg.splice(0)

            if(arr.length > 0){
                arr.forEach(idx => {
                    let path = remoteURL + 'opr/imgView?fileNm=' + idx.fileNm + '&filePth=' + idx.filePth + '&fileKndCd=2'
                    this.mainImg.push(path)
                });
            }
        },
        setTour(arr) {
            this.fcltyTour.splice(0)

            if(arr.length > 0){
                arr.forEach(idx => {
                    let path = remoteURL + 'opr/imgView?fileNm=' + idx.bbsFileNm + '&filePth=' + idx.bbsFilePth + '&fileKndCd=2'
                    this.fcltyTour.push(path)
                });
            }
        },
    },

    persist: {
        enabled: true,
        storage: sessionStorage
    },
})

export{ clntInf, lgnInf, notiInf, displayInfStore, glbDlg, glbRules, authHstng, stateHstng }