/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

export async function loadFonts () {
    const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

    webFontLoader.load({
        google: {
            families: [
                'Noto Sans KR:100,200,300,400,500,600,700,800,900', 'sans-serif'
            ]
        },
    })
    webFontLoader.load({
        google: {
            families: [
                'Gothic A1:100,200,300,400,500,600,700,800,900', 'sans-serif'
            ]
        },
    })
    webFontLoader.load({
        google: {
            families: [
                'Do Hyeon:400', 'sans-serif'
            ]
        },
    })
}
